import axios, {AxiosRequestConfig} from "axios";

const baseLocalHostUrl = 'https://localhost:44386/api'
const baseDevHostUrl = 'https://dev-api.superchefbcm.com/api';
const baseProdHostUrl = 'https://api.superchefbcm.com/api';

export class BaseWebService {
    private baseUrl = '';
    private controller = '';
    private apiLocation = '';

    /**
     * Represents the base Axios configuration for making HTTP requests.
     * @type {AxiosRequestConfig} The base Axios configuration.
     */
    baseAxiosConfiguration: AxiosRequestConfig = {
        withCredentials: true,
        headers: {'Content-Type': 'application/json'}
    };

    constructor(providedController: string) {
        if (process.env.REACT_APP_DEV_ENV === 'production') {
            this.baseUrl = baseProdHostUrl;
        } else if (process.env.REACT_APP_DEV_ENV === 'development') {
            this.baseUrl = baseDevHostUrl;
        } else {
            this.baseUrl = baseLocalHostUrl;
        }

        this.controller = providedController;
    }

    /**
     * Builds the path for the HTTP request.
     * @param {action} action The action to be performed.
     * @param {newController} newController The controller to be used.
     * @returns {string} - The path for the HTTP request.
     */
    private buildPath(action: string, newController: string = "") {
        let path = `${this.baseUrl}/`;
        if (newController !== "") {
            path += `${newController}/${action}`;
        } else {
            path += `${this.controller}/${action}`;
        }

        return path
    }

    /**
     * Gets the base Axios configuration for making HTTP requests.
     * @param {params} params The parameters to be used in the HTTP request.
     * @returns {AxiosRequestConfig} The base Axios configuration.
     */
    private GetConfig(params: any = null): AxiosRequestConfig {
        let baseConfig: AxiosRequestConfig = {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }

        if (params != null) {
            baseConfig.params = params;
        }

        return baseConfig;
    }

    /**
     * Makes a GET request to the API.
     * @param {action} action The action to be performed.
     * @param {params} params The parameters to be used in the HTTP request.
     * @returns {Promise<any>} Promise<any> - The response object.
     */
    public Get(action: string, params: any = null): Promise<any> {
        let path = this.buildPath(action);
        let config = this.GetConfig(params);
        return axios.get(path, config);
    }

    /**
     * Makes a GET request to the API.
     * @param {action} action The action to be performed.
     * @returns {Promise<any>} Promise<any> - The response object.
     */
    public GetAll(action:string):Promise<any>{
        let path = this.buildPath(action);
        let config = this.GetConfig();
        return axios.get(path,config);
    }

    /**
     * Makes a POST request to the API.
     * @param {action} action The action to be performed.
     * @param {params} params The parameters to be used in the HTTP request.
     * @returns {Promise<any>} Promise<any> - The response object.
     */
    public Post(action: string, params: any): Promise<any> {
        let path = this.buildPath(action);
        let config = this.GetConfig();
        return axios.post(path, params, config);
    }

    /**
     * Makes a POST request to the API and overrides the base POST controller.
     * @param {controller} controller The controller to be used.
     * @param {action} action The action to be performed.
     * @param {params} params The parameters to be used in the HTTP request.
     * @returns {Promise<any>} Promise<any> - The response object.
     */
    public PostControllerOverride(controller: string, action: string, params: any): Promise<any> {
        let path = controller;
        path += `/${action}`
        let config = this.baseAxiosConfiguration;

        return axios.post(path, params, this.GetConfig());
    }

    /**
     * Makes a PUT request to the API.
     * @param {action} action The action to be performed.
     * @param {params} params The parameters to be used in the HTTP request.
     * @returns {Promise<any>} Promise<any> - The response object.
     */
    public Put(action: string, params: { id:any, objIn:any }):Promise<any> {
        let path = this.buildPath(action);
        path+= `/${params.id}`
        let config = this.GetConfig();
        return axios.put(path, params.objIn, config);
    }

    /**
     * Makes a custom PUT request to the API.
     * @param {action} action The action to be performed.
     * @param {params} params The parameters to be used in the HTTP request.
     * @returns {Promise<any>} Promise<any> - The response object.
     */
    public CustomPut(action: string, params:any):Promise<any> {
        let path = this.buildPath(action);        
        let config = this.GetConfig();
        return axios.put(path, params, config);
    }

    /**
     * Makes a DELETE request to the API.
     * @param {action} action The action to be performed.
     * @param {id} id The id of the object to be deleted.
     * @returns {Promise<any>}
     */
    public Delete(action: string, id: string) {
        let path = this.buildPath(action);
        return axios.delete(path, this.GetConfig({id}));
    }
}
