const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <p>
                    <span className={'bold'}>
                    Contact us:
                </span>{" "}
                    <a href="mailto:SuperChef@bcm.edu" className={'email-link'} target={'_blank'} rel={'noreferrer'}>
                        SuperChef@bcm.edu
                    </a>
                </p>
            </div>
        </footer>
    )
}

export default Footer
