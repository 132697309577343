import { ElementType, Suspense } from 'react';

const PageLoader = (PageComponent:ElementType) => (innerProps:any)=>
    (
        //Suspense will cause load delay, and while component is loading will display fallback component
        //TODO: ADD LOADING PAGE
        <Suspense fallback={ <></> } >
            <PageComponent {...innerProps}/>
        </Suspense>
    );

export default PageLoader
