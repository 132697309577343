import { BaseWebService } from "_api/services/interfaces/baseWebService";
import { UpdateToolboxLogRequest } from "data/dto/logs/updateToolboxLogRequest";

export class ToolboxLogService extends BaseWebService {
    constructor() {
        super('ToolboxLog');
    }

    /**
     * Updates a single toolbox log document
     * @param {UpdateToolboxLogRequest} req - The request object
     * @returns {Promise<any | null>} Promise<any | null> - Returns the updated toolbox log document
     */    
    public UpdateLog(req:UpdateToolboxLogRequest): Promise<any | null>{
        const endpoint = "UpdateLog";
        return this.CustomPut(endpoint, req);
    }
}
