import { ToolboxLogController } from "_api/controllers/toolboxLogController";
import { UpdateToolboxLogRequest } from "data/dto/logs/updateToolboxLogRequest";
import { ToolboxCategory } from "data/utilities/toolboxCategory";
import { Link } from "react-router-dom";
import { GetReduxState } from "../../hooks/redux-hooks";
import { RootState } from "../../stores";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Session1VideoTitle,
    Session1VideoUrl,
    Session2VideoTitle,
    Session2VideoUrl,
    Session3VideoTitle,
    Session3VideoUrl,
    WrapUpVideoTitle,
    WrapUpVideoUrl,
    CuttingTechniquesVideoTitle,
    CuttingTechniquesVideoUrl,
    MeasuringTechniquesVideoTitle,
    MeasuringTechniquesVideoUrl
} from "../../data/models/videos/videos";
import {IoIosVideocam} from "react-icons/io";

const toolboxLogController = new ToolboxLogController();

type SidebarProps = {
    isOpen: boolean;
    setIsOpen: any;
}

const Sidebar = ({isOpen, setIsOpen}: SidebarProps) => {
    const sidebarRef = useRef(null)

    /**
     * Converts a string to a slug
     * @param videoTitle
     */
    const convertToSlug = (videoTitle: string) => {
        return videoTitle
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
    }

    /**
     * Gets the user session from redux
     */
    const userSession = GetReduxState((state: RootState) => state.Authentication.user)

    /**
     * Handle sidebar toggle
     */
    const handleSidebar = () => {
        setIsOpen((prevState: any) => !prevState)
    }

    /**
     * Update the toolbox log when a link is clicked
     * @param contentName
     * @param category
     */
    const updateLogOnLinkClick = (contentName:string, category:ToolboxCategory) => {
        const {loginType} = userSession;
        const req:UpdateToolboxLogRequest = {
            contentName,
            SessionType: loginType,
            category
        }
        toolboxLogController.UpdateLog(req);
    }

    /**
     * Handle click outside of sidebar
     */
    useEffect(() => {
        const handleClickOutside = (e: any) => {
            // @ts-ignore
            if (sidebarRef.current && !sidebarRef.current!.contains(e.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }

    }, [sidebarRef, setIsOpen])

    /**
     * Determines if the sidebar links should be shown based on the user's current session and progress
     * @param session
     */
    const showSidebarLinks = useCallback((session: number) => {
        if (userSession.currentSession >= session && userSession.currentProgress === 2) {
            return true
        }

        if ((userSession.currentSession > session && userSession.currentProgress === 1 && userSession.loginType === 0) || (userSession.currentSession >= session && userSession.loginType === 1)) {
            return true
        }

        if (userSession.currentProgress === 4) {
            return true
        }

        return false

    }, [userSession])

    /**
     * Determines if the progress bar should be shown based on the user's current session and progress
     */
    const showProgressBar = () => {
        if (userSession.currentSession >= 1 && userSession.currentProgress === 2) {
            return true
        }

        if (userSession.currentSession >= 1 && userSession.currentProgress === 4) {
            return true
        }

        if (userSession.currentSession >= 1 && userSession.loginType === 0) {
            return true
        }

        if (userSession.currentSession >= 1 && userSession.loginType === 1) {
            return true
        }

        return false
    }

    /**
     * Shows the sidebar links based on the user's current session and progress on load
     */
    useEffect(() => {
        showSidebarLinks(0)
        showSidebarLinks(1)
        showSidebarLinks(2)
        showSidebarLinks(3)

        return () => {
            showSidebarLinks(0)
            showSidebarLinks(1)
            showSidebarLinks(2)
            showSidebarLinks(3)
        }
    }, [showSidebarLinks, userSession]);

    /**
     * Prevents the body from scrolling when the sidebar is open
     */
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    return (
        <div className={`${isOpen ? 'sidebar-backdrop active' : ''}`}>
            <div className={`sidebar-container ${isOpen ? 'active' : ''}`} ref={sidebarRef}>
                {/* Menu Tab */}
                <button className='btn sidebar' onClick={handleSidebar}>
                    <span>
                        Toolbox
                    </span>
                </button>
                {/* Sidebar Content */}
                <div className="sidebar-content">
                    <div className={'sidebar-wrapper'}>
                        {/* Toolbox - Menu Title */}
                        <div className={'sidebar-menu-title-container'}>
                            <h6 className={'links-title'}>
                                Helpful Information
                            </h6>
                        </div>

                        <div className={'sidebar-links-container'}>
                            <ul className={'sidebar-links'}>
                                {/* Progress Bar */}
                                {showProgressBar() && (
                                    <li>
                                        <Link to={'/dashboard/progress'}
                                              onClick={() => updateLogOnLinkClick("See Your Super Chef Progress", ToolboxCategory.Archive)}>
                                            See Your Super Chef Progress
                                        </Link>
                                    </li>
                                )}
                                <li>
                                    <Link to={'/images/documents/general/Mediterranean-Diet.pdf'} target="_blank"
                                          rel='noopener noreferrer'
                                          onClick={() => updateLogOnLinkClick("Mediterranean Diet Overview", ToolboxCategory.Archive)}>
                                        Mediterranean Diet Overview
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/images/documents/general/Abbreviation-Measurements.pdf'} target="_blank"
                                          rel='noopener noreferrer'
                                          onClick={() => updateLogOnLinkClick("Measurement Abbreviations", ToolboxCategory.Archive)}>
                                        Measurement Abbreviations
                                    </Link>
                                </li>
                                <li className={'link-icon-container'}>
                                    <Link to={`/video/${convertToSlug(CuttingTechniquesVideoTitle)}`}
                                          state={{
                                              title: CuttingTechniquesVideoTitle,
                                              videoUrl: CuttingTechniquesVideoUrl
                                          }}
                                          onClick={() => updateLogOnLinkClick(CuttingTechniquesVideoTitle, ToolboxCategory.Archive)}
                                    >
                                        {CuttingTechniquesVideoTitle} <span><IoIosVideocam/></span>
                                    </Link>
                                </li>
                                <li className={'link-icon-container'}>
                                    <Link to={`/video/${convertToSlug(MeasuringTechniquesVideoTitle)}`}
                                          state={{
                                              title: MeasuringTechniquesVideoTitle,
                                              videoUrl: MeasuringTechniquesVideoUrl
                                          }}
                                          onClick={() => updateLogOnLinkClick(MeasuringTechniquesVideoTitle, ToolboxCategory.Archive)}
                                    >
                                        {MeasuringTechniquesVideoTitle} <span><IoIosVideocam/></span>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        {/* Session 1 - Links */}
                        {showSidebarLinks(0) && (
                            <div className={'sidebar-links-container'}>
                                <h6 className={'links-title'}>
                                    Session 1
                                </h6>
                                <ul className={'sidebar-links'}>
                                    <li>
                                        <Link to={'/images/documents/sessionOne/Session-1-Whole-Wheat-Pasta-Recipe.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Super Chef Recipe", ToolboxCategory.SessionOne)}>
                                            Super Chef Recipe
                                        </Link>
                                    </li>
                                    <li className={'link-icon-container'}>
                                        <Link to={`session-one/video/${convertToSlug(Session1VideoTitle)}`}
                                              state={{
                                                  title: Session1VideoTitle,
                                                  videoUrl: Session1VideoUrl
                                              }}
                                              onClick={() => updateLogOnLinkClick(Session1VideoTitle, ToolboxCategory.SessionOne)}
                                        >
                                            {Session1VideoTitle} <span><IoIosVideocam/></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionOne/Session-1-Cooking-Tips.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Super Chef Tips for Modifying Family Favorite Recipes", ToolboxCategory.SessionOne)}>
                                            Super Chef Tips for Modifying Family Favorite Recipes
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionOne/Session-1-Cooking-with-Kids.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Cooking Tips/Hacks", ToolboxCategory.SessionOne)}>
                                            Tips for Cooking With Kids
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionOne/Session-1-Parent-Super-Powers.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Parent Super Powers Tips", ToolboxCategory.SessionOne)}>
                                            Parent Super Powers Tips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionOne/Session-1-Cooking-Strategy.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Cooking Strategy Tips", ToolboxCategory.SessionOne)}>
                                            Cooking Strategy Tips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionOne/Session-1-Health-Tips.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Health Tips", ToolboxCategory.SessionOne)}>
                                            Health Tips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionOne/Session-1-Links.pdf'} target="_blank"
                                              rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Links to External Websites", ToolboxCategory.SessionOne)}>
                                            Links to External Websites
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}

                        {/* Session 2 - Links */}
                        {showSidebarLinks(1) && (
                            <div className={'sidebar-links-container'}>
                                <h6 className={'links-title'}>
                                    Session 2
                                </h6>
                                <ul className={'sidebar-links'}>
                                    <li>
                                        <Link
                                            to={'/images/documents/sessionTwo/Session-2-Chicken-Veggie-Stir-Fry Recipe.pdf'}
                                            target="_blank" rel='noopener noreferrer'
                                            onClick={() => updateLogOnLinkClick("Super Chef Recipe", ToolboxCategory.SessionTwo)}>
                                            Super Chef Recipe
                                        </Link>
                                    </li>
                                    <li className={'link-icon-container'}>
                                        <Link to={`session-two/video/${convertToSlug(Session2VideoTitle)}`}
                                              state={{
                                                  title: Session2VideoTitle,
                                                  videoUrl: Session2VideoUrl
                                              }}
                                              onClick={() => updateLogOnLinkClick(Session2VideoTitle, ToolboxCategory.SessionTwo)}
                                        >
                                            {Session2VideoTitle} <span><IoIosVideocam/></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionTwo/Session-2-Cooking-TipsHacks.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Super Chef Tips for Modifying Family Favorite Recipes", ToolboxCategory.SessionTwo)}>
                                            Super Chef Tips for Modifying Family Favorite Recipes
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionTwo/Session-2-Cooking-with-Kids.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Cooking Tips/Hacks", ToolboxCategory.SessionTwo)}>
                                            Tips for Cooking With Kids
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionTwo/Session-2-Parent-Super-Powers.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Parent Super Powers Tips", ToolboxCategory.SessionTwo)}>
                                            Parent Super Powers Tips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionTwo/Session-2-Cooking-strategy.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Cooking Strategy Tips", ToolboxCategory.SessionTwo)}>
                                            Cooking Strategy Tips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionTwo/Session-2-Health-Tip.pdf'}
                                              target="_blank" rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Health Tips", ToolboxCategory.SessionTwo)}>
                                            Health Tips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionTwo/Session-2-Links.pdf'} target="_blank"
                                              rel='noopener noreferrer'
                                              onClick={() => updateLogOnLinkClick("Links to External Websites", ToolboxCategory.SessionTwo)}>
                                            Links to External Websites
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}

                        {/* Session 3 - Links */}
                        {showSidebarLinks(2) && (
                            <div className={'sidebar-links-container'}>
                                <h6 className={'links-title'}>
                                    Session 3
                                </h6>
                                <ul className={'sidebar-links'}>
                                    <li>
                                        <Link to={'/images/documents/sessionThree/Session-3-Turkey-Tacos-Recipe.pdf'} target="_blank" rel='noopener noreferrer' onClick={() => updateLogOnLinkClick("Super Chef Recipe", ToolboxCategory.SessionThree)}>
                                            Super Chef Recipe
                                        </Link>
                                    </li>
                                    <li className={'link-icon-container'}>
                                        <Link to={`session-three/video/${convertToSlug(Session3VideoTitle)}`}
                                              state={{
                                                  title: Session3VideoTitle,
                                                  videoUrl: Session3VideoUrl
                                              }}
                                              onClick={() => updateLogOnLinkClick(Session3VideoTitle, ToolboxCategory.SessionThree)}
                                        >
                                            {Session3VideoTitle} <span><IoIosVideocam/></span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionThree/Session-3-Cooking-Tips.pdf'} target="_blank" rel='noopener noreferrer' onClick={() => updateLogOnLinkClick("Super Chef Tips for Modifying Family Favorite Recipes", ToolboxCategory.SessionThree)}>
                                            Super Chef Tips for Modifying Family Favorite Recipes
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionThree/Session-3-Cooking-with-Kids.pdf'} target="_blank" rel='noopener noreferrer' onClick={() => updateLogOnLinkClick("Tips for Cooking With Kids", ToolboxCategory.SessionThree)}>
                                            Tips for Cooking With Kids
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionThree/Session-3-Parent-Super-Powers.pdf'} target="_blank" rel='noopener noreferrer' onClick={() => updateLogOnLinkClick("Parent Super Powers Tips", ToolboxCategory.SessionThree)}>
                                            Parent Super Powers Tips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionThree/Session-3-Cooking-Strategy.pdf'} target="_blank" rel='noopener noreferrer' onClick={() => updateLogOnLinkClick("Cooking Strategy Tips", ToolboxCategory.SessionThree)}>
                                            Cooking Strategy Tips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionThree/Session-3-Health-Tips.pdf'} target="_blank" rel='noopener noreferrer' onClick={() => updateLogOnLinkClick("Health Tips", ToolboxCategory.SessionThree)}>
                                            Health Tips
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/images/documents/sessionThree/Session-3-Links.pdf'} target="_blank" rel='noopener noreferrer' onClick={() => updateLogOnLinkClick("Links to External Websites", ToolboxCategory.SessionThree)}>
                                            Links to External Websites
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}

                        {/* Session 4 - Links */}
                        {showSidebarLinks(3) && (
                            <div className={'sidebar-links-container'}>
                                <h6 className={'links-title'}>
                                    Session 4
                                </h6>
                                <ul className={'sidebar-links'}>
                                    <li className={'link-icon-container'}>
                                        <Link to={`session-four/video/${convertToSlug(WrapUpVideoTitle)}`}
                                              state={{
                                                  title: WrapUpVideoTitle,
                                                  videoUrl: WrapUpVideoUrl
                                              }}
                                              onClick={() => updateLogOnLinkClick(WrapUpVideoTitle, ToolboxCategory.SessionFour)}
                                        >
                                            {WrapUpVideoTitle} <span><IoIosVideocam/></span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar