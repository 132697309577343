import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CurrentUserSession} from "../../data/models/session/currentUserSession";
import {Sessions} from "../../data/models/session/sessions";

interface SessionProps {
    userSession: CurrentUserSession;
}

const SessionSlice = createSlice({
    name: 'Session',
    initialState:  {
        userSession: new CurrentUserSession()
    },
    reducers: {
        deleteSession: (state, action: PayloadAction<SessionProps>) => {
            state.userSession = new CurrentUserSession();
        },
        setSession: (state,action: PayloadAction<SessionProps>)=> {
            state.userSession = action.payload.userSession;
        },
        setSessionData: (state, action: PayloadAction<Sessions>) => {
            return {
                ...state,
                userSession: {
                    ...state.userSession,
                    currentSessionData: action.payload
                }
            };
        },
        clearSessionState: (state) => {
            state.userSession = new CurrentUserSession();
        }
    }
})

export default SessionSlice.reducer

const sessionSelector = (state: { session: { userSession: CurrentUserSession; }; }) => state.session.userSession;

export const { deleteSession, setSession, setSessionData, clearSessionState} = SessionSlice.actions
