import {ReactElement, useEffect} from 'react';
import {  useNavigate } from 'react-router-dom';
import useAuth from "../hooks/useAuth";

export type AuthGuardProps = {
    children: ReactElement | null;
};

const AuthGuard = ({ children }: AuthGuardProps) => {
    const { CheckStatus} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const checkStatus = async(check:any)=>{
            let response = await check();
            if(!response){
                navigate('/', {replace: true})
            }
        }

        checkStatus(CheckStatus)
    }, [navigate]);

    return children;
};

export default AuthGuard;
