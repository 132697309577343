import { SessionType } from "../profile/sessionType";
import {Sections} from "./sections";

export interface ISession {
    sessionName : string
    type:SessionType,
    sections:Sections[],
    completedAt: Date,
    createdAt: Date,
}

export class Sessions implements ISession {
    sessionName : string
    type:SessionType
    sections:Sections[]
    completedAt: Date
    createdAt: Date

    constructor() {
        this.sessionName = "";
        this.type = SessionType.Both;
        this.sections = [];
        this.completedAt = new Date();
        this.createdAt = new Date();
    }
}
