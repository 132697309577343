import {createContext, FunctionComponent, ReactElement} from "react";
import {UserController} from "_api/controllers/userController";
import {DispatchAction, GetReduxState} from "hooks/redux-hooks";
import {login, logout} from "../stores/reducers/authSlice";
import {FamilyProfile} from "../data/models/profile/familyProfile";
import {AuthenticationRequest} from "../data/models/auth/authenticationRequest";

interface AuthenticationContextProps {
    user: FamilyProfile;
    Login: (request:AuthenticationRequest) => Promise<FamilyProfile | null>;
    Logout: ()  => Promise<void>;
    CheckStatus: () => Promise<boolean>
}

interface AuthProviderProps {
    children: ReactElement
}

const AuthContext = createContext<AuthenticationContextProps | null>(null);

export const AuthProvider : FunctionComponent<AuthProviderProps> = ({children}) => {
    //Give State and way to update state through redux so that on update causes refresh.
    const {user} = GetReduxState(state=> state.Authentication);

    const dispatch = DispatchAction();
    const userController:UserController = new UserController();

    /**
     * Login context function
     * @param request
     * @returns {Promise<FamilyProfile | null>}
     */
    async function Login(request: AuthenticationRequest){
        const response: FamilyProfile|null = await userController.Authenticate(request);

        if(response != null)
        {
            dispatch(login({
                isLoggedIn: true,
                user: response
            }))

           return response;

        } else {
           return null;
        }
    }

    /**
     * Logout context function
     * @returns {Promise<void>}
     */
    async function Logout(): Promise<void> {
        const response = await userController.Logout();

        dispatch(logout({
            isLoggedIn: false,
            user: new FamilyProfile()
        }))

    }

    /**
     * Check Status context function
     * @returns {Promise<boolean>}
     */
    async function CheckStatus(): Promise<boolean> {
        let validToken =  await userController.CheckStatus();
        if(validToken) {
            return true;
        }

        dispatch(logout({
            isLoggedIn: false,
            user: new FamilyProfile()
        }))
        return false;
    }

    return (
        //Pass in functions and current state so that it can be accessed via hook in context
        <AuthContext.Provider value={{user ,Login, Logout, CheckStatus}}>
            {children}
        </AuthContext.Provider>
    )
}
export default AuthContext

