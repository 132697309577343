import React from "react";
import superChefLogo from 'assets/images/SuperChef_Logo04.png';
import {Link, useLocation} from "react-router-dom";

type HeaderProps = {
    headerClass?: string;
}

export const Header = ({headerClass}: HeaderProps) => {
    const location = useLocation();

    /**
     * Determine which page to go to when clicking the logo
     */
    const handlePage = () => {
        if (location.pathname !== '/') {
            return '/dashboard';
        }

        return '/';
    }

    return(
        <div className="wrapper-2">
            <div className={headerClass ? headerClass : 'header'}>
                <div className={'header-content'}>
                    <div className="logo">
                        <Link to={handlePage()}>
                            <img src={superChefLogo} alt="Super Chef" width="100%" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
