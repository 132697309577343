import React from "react";
import {Header} from "../Header";
import Footer from "../Footer";

type ExternalLayoutProps = {
    children: React.ReactNode
}

const ExternalLayout = ({children}: ExternalLayoutProps) => {
    return (
        <>
            <Header headerClass={'header-2'}/>
                {children}
            <Footer />
        </>
    )
}

export default ExternalLayout