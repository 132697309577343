import { ToolboxLogService } from "_api/services/toolboxLogService";
import { UpdateToolboxLogRequest } from "data/dto/logs/updateToolboxLogRequest";

/**
 * Controller for interacting with the toolbox logs 
 */
export class ToolboxLogController {
    private toolboxLogService:ToolboxLogService;    

    constructor() {
        this.toolboxLogService = new ToolboxLogService();
    }

        /**
         * Updates a single toolbox log document
         * @param {req} req - The request to update the toolbox log
         * @type {UpdateToolboxLogRequest} UpdateToolboxLogRequest - The request to update the toolbox log
         * @returns {Promise<any | null>} Promise<any | null> - Returns the updated toolbox log if successful, otherwise returns `null`.
         */
    public async UpdateLog(req:UpdateToolboxLogRequest) {
        try {
            let data = (await this.toolboxLogService.UpdateLog(req)).data;           
            return data;            
        } catch (error:any) {
            let er = error.response.data;
            //We are logging to console here instead of creating a toast because this log should not interfere with the user experience
            console.error(er);
            return null;
        }
    }
}


