import {SessionType} from "./sessionType";
import {SessionProgress} from "./sessionProgress";

export class FamilyProfile {
    userProfileId: string;
    parentUserName: string;
    childUserName: string;
    currentSession: number;
    currentSection: number;
    currentProgress: SessionProgress;
    isSectionComplete: boolean;
    profileScore: number;
    loginType: SessionType;
    constructor()
    {
        this.userProfileId = "";
        this.parentUserName = "";
        this.childUserName = "";
        this.currentSession = 0;
        this.currentSection = 0;
        this.currentProgress = 1;
        this.isSectionComplete = false;
        this.profileScore = 0;
        this.loginType = 1;
    }
}
