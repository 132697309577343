import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FamilyProfile} from "../../data/models/profile/familyProfile";

interface AuthenticationProps {
    isLoggedIn: boolean;
    user: FamilyProfile;
}

const Authentication = createSlice({
    name:'Authentication',
    initialState:  {
        isLoggedIn: false,
        user: new FamilyProfile()
    },
    reducers:{
        logout: (state, action: PayloadAction<AuthenticationProps>) => {
            state.isLoggedIn = false;
            state.user = new FamilyProfile();
        },
        login:(state,action: PayloadAction<AuthenticationProps>)=> {
            state.isLoggedIn = true;
            state.user = action.payload.user;
        },
        resetCurrentSection: (state) => {
            state.user.currentSection = 0;
        },
        progressToNextSection: (state) => {
            state.user.currentSection += 1;
        },
        progressToPreviousSection: (state) => {
            state.user.currentSection -= 1;
        },
        progressToNextSession: (state) => {
            state.user.currentSession += 1;
            state.user.currentSection = 0;
        },
        progressToPreviousSession: (state) => {
            state.user.currentSession -= 1;
            state.user.currentSection = 0;
        },
        setCurrentProgress: (state, action: PayloadAction<number>) => {
            state.user.currentProgress = action.payload;
        },
        setCurrentSession: (state, action: PayloadAction<number>) => {
            state.user.currentSession = action.payload;
        },
        setCurrentSection: (state, action: PayloadAction<number>) => {
            state.user.currentSection = action.payload;
        },
        setProfileScore: (state, action: PayloadAction<number>) => {
            state.user.profileScore = action.payload;
        },
        clearUserState: (state) => {
            state.isLoggedIn = false;
            state.user = new FamilyProfile();
        }
    }
})

export default Authentication.reducer

const authenticationSelector = (state: { authentication: { user: FamilyProfile; }; }) => state.authentication.user;

export const {
    logout,
    login,
    progressToNextSection, progressToPreviousSection, progressToNextSession, progressToPreviousSession,
    setCurrentProgress,
    resetCurrentSection,
    setCurrentSession,
    setCurrentSection,
    setProfileScore,
    clearUserState
} = Authentication.actions