import {combineReducers} from "@reduxjs/toolkit";
import Authentication from './authSlice';
import Session from "./sessionSlice";


const ReduxReducers = combineReducers({
  Authentication,
  Session
})

export default ReduxReducers;
