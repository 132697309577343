import {AuthService} from "_api/services/authService";
import {AuthenticationRequest} from "data/models/auth/authenticationRequest";
import {FamilyProfile} from "data/models/profile/familyProfile";
import toast from "react-hot-toast";

export class UserController {
    private authService: AuthService;
    private formatISO = require('date-fns/formatISO')

    constructor() {
        this.authService = new AuthService();
    }

    /**
     * Authenticates the user and returns the profile
     * @param {request} request
     * @returns {Promise<FamilyProfile | null>} Promise<FamilyProfile | null> - Returns the family profile if successful, otherwise returns `null`.
     */
    public async Authenticate(request: AuthenticationRequest): Promise<FamilyProfile | null> {
        try {
            let data = (await this.authService.Login(request)).data;
            let profile = data;
            return profile;
        }
        catch (error: any) {
            let er = error.response.data;
            toast.error(er);
            return null;
        }
    }

    /**
     * Checks the status of the user
     * @returns {Promise<boolean | null>} Promise<boolean | null> - Returns `true` if successful, otherwise returns `null`.
     */
    public async CheckStatus(): Promise<boolean | null> {
        try {
            let data = (await this.authService.CheckStatus()).data;
            return data
        }
        catch (error: any) {
            let er = error.response.data;
            toast.error(er);
            return false;
        }
    }

    /**
     * Logs the user out
     * @returns {Promise<boolean>} Promise<boolean> - Returns `true` if successful, otherwise returns `false`.
     */
    public async Logout(): Promise<boolean> {
        try {
            return await this.authService.Logout();
        }
        catch (error: any) {
            let er = error.response.data;
            toast.error(er);
            return false
        }
    }

}


