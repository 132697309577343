import { lazy } from 'react';
import {Outlet} from "react-router";
import AuthGuard from "./authGuard";
import PageLoader from "../components/shared/PageLoader";
import InternalLayout from "../components/layout/internal/internalLayout";
import LoginTypeGuard from "./loginTypeGuard";
import StaticVideoViewer from "../components/sessions/sidebar/StaticVideoViewer";

const Introduction = PageLoader(lazy(() => import("pages/internal/sessions/introduction")));
const SessionOne = PageLoader(lazy(() => import("pages/internal/sessions/sessionOne")));
const SessionTwo = PageLoader(lazy(() => import("pages/internal/sessions/sessionTwo")));
const SessionThree = PageLoader(lazy(() => import("pages/internal/sessions/sessionThree")));
const SessionFour = PageLoader(lazy(() => import("pages/internal/sessions/sessionFour")));
const ProgressPage = PageLoader(lazy(() => import("pages/internal/sessions/progress")));
const Dashboard = PageLoader(lazy(() => import("pages/internal/dashboard")));
const Logout = PageLoader(lazy(() => import("pages/internal/auth/Logout")));
const VideoViewer = PageLoader(lazy(() => import("components/sessions/sidebar/VideoViewer")));
const ThankYou = PageLoader(lazy(() => import("components/sessions/sections/ThankYou")));
const NotFoundPage = PageLoader(lazy(()=> import("pages/internal/notFound")));
// const ReviewPage = PageLoader(lazy(() => import("pages/internal/sessions/review")));

const InternalRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <AuthGuard>
                    <InternalLayout>
                        <Outlet />
                    </InternalLayout>
                </AuthGuard>
            ),
            children:[
                {
                    path: '/dashboard',
                    element: (
                        <Dashboard/>
                    )
                },
                {
                    path: '/dashboard/progress',
                    element: (
                        <ProgressPage />
                    )
                },
                {
                    path: '/introduction',
                    element: (
                        <LoginTypeGuard>
                            <Introduction/>
                        </LoginTypeGuard>
                    )
                },
                {
                    path: '/session-one',
                    element: (
                        <LoginTypeGuard>
                            <SessionOne />
                        </LoginTypeGuard>
                    )
                },
                {
                    path: '/session-two',
                    element: (
                        <LoginTypeGuard>
                            <SessionTwo />
                        </LoginTypeGuard>
                    )
                },
                {
                    path: '/session-three',
                    element: (
                        <LoginTypeGuard>
                            <SessionThree />
                        </LoginTypeGuard>
                    )
                },
                {
                    path: '/session-four',
                    element: (
                        <LoginTypeGuard>
                            <SessionFour />
                        </LoginTypeGuard>
                    )
                },
                {
                    path: '/logout',
                    element: (
                        <Logout />
                    )
                },
                {
                    path: '/:sessionNumber/thank-you',
                    element: (
                        <ThankYou />
                    )
                },
                {
                    path: '/:sessionNumber/video/:title',
                    element: (
                        <VideoViewer />
                    )
                },
                {
                    path: '/video/:title',
                    element: (
                        <StaticVideoViewer />
                    )
                },
                // {
                //     path: '/:sessionNumber/review',
                //     element: (
                //         <LoginTypeGuard>
                //             <ReviewPage />
                //         </LoginTypeGuard>
                //     )
                // },
                {
                    path: '*',
                    element: (
                        <NotFoundPage/>
                    )
                }
            ]
        }

    ]
};

export default InternalRoutes;
