import { createContext } from "react";

type formContext = {
    isSubmitting:boolean;
    updateIsSubmitting:(isSubmitting:boolean) => void
}

const defaultContext:formContext = {
    isSubmitting:false,
    updateIsSubmitting: () => {}
}

export const FormContext = createContext(defaultContext)
