import {BaseWebService} from "_api/services/interfaces/baseWebService";
import {SessionNumber} from "data/models/profile/sessionNumber";
import {SessionUpdateRequest} from "data/dto/profile/SessionUpdateRequest";

export class SessionService extends BaseWebService {
    constructor() {
        super('FamilyProfile');
    }

    /**
     * Updates a user's session data
     * @param {profileId} profileId - The user's profile id
     * @param {request} request - The request object
     * @returns {Promise<boolean | null>} Promise<boolean | null> - Returns true if successful, false if not
     */
    public UpdateUserSession(profileId: string, request: SessionUpdateRequest): Promise<any> {
        const endpoint = 'UpdateSessionSection';
        return this.Put(endpoint, {id: profileId, objIn: request});
    }

    /**
     * Gets a user's session data
     * @param {id} id - The user's profile id
     * @param {session} session - The session number
     * @returns {Promise<any | null>} Promise<any | null> - Returns the user's session data
     */
    public GetSessionSection(id: string, session: SessionNumber): Promise<any> {
        const endpoint = 'GetSessionSections';
        return this.Get(endpoint, {profileId: id, session: session});
    }

    /**
     * Gets a user's family profile
     * @param {id} id - The user's profile id
     * @returns {Promise<any | null>} Promise<any | null> - Returns the user's family profile
     */
    public GetFamilyProfile(id: string): Promise<any> {
        const endpoint = 'GetFamilyProfile';
        return this.Get(endpoint, {profileId: id});
    }

    /**
     * Updates a user's family profile score
     * @param {id} id - The user's profile id
     * @param {updatedPoints} updatedPoints - The updated points
     * @returns {Promise<any | null>} Promise<any | null> - Returns the user's family profile
     */
    public async UpdateFamilyProfileScore(id: string, updatedPoints: number): Promise<any | null> {
       const endpoint = 'UpdateFamilyProfileScore';
       return this.Put(endpoint, {id: id, objIn: updatedPoints});
    }

    /**
     * Updates a user's family profile video count
     * @param {id} id - The user's profile id
     * @param {request} request - The request object
     * @returns {Promise<any | null>} Promise<any | null> - Returns the user's family profile
     */
    public async UpdateFamilyVideoCount(id: string, request: SessionUpdateRequest): Promise<any | null> {
        const endpoint = 'UpdateFamilyVideoCount';
        return this.Put(endpoint, {id: id, objIn: request});
    }

    /**
     * Updates a user's family profile session start time
     * @param {sessionNumber} sessionNumber - The session number
     * @returns {Promise<any | null>} Promise<any | null> - Returns the user's family profile
     */
    public async UpdateSessionStartTime(sessionNumber:SessionNumber): Promise<any | null> {
        const endpoint = "UpdateSessionStartTime";
        return this.CustomPut(endpoint, sessionNumber);
    }

    /**
     * Updates a user's family profile session end time
     * @param {sessionNumber} sessionNumber - The session number
     * @returns {Promise<any | null>} Promise<any | null> - Returns the user's family profile
     */
    public async UpdateSessionEndTime(sessionNumber:SessionNumber): Promise<any | null> {
        const endpoint = "UpdateSessionEndTime";
        return this.CustomPut(endpoint, sessionNumber);
    }
}
