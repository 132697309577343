import {ReactElement, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";

type LoginTypeGuardProps = {
    children: ReactElement | null;
}

/**
 * Redirects user to dashboard if user is logged in as a parent or child
 * @param children
 * @returns {ReactElement | null}
 */
const LoginTypeGuard = ({children}: LoginTypeGuardProps): ReactElement | null => {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user.loginType === 0 || user.loginType === 1) {
            navigate('/dashboard', { replace: true })
        }
    }, [navigate, user.loginType]);

    return children;
}

export default LoginTypeGuard;