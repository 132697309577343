import toast from "react-hot-toast";
import {SessionService} from "_api/services/sessionService";
import {SessionNumber} from "data/models/profile/sessionNumber";
import {SessionUpdateRequest} from "data/dto/profile/SessionUpdateRequest";

export class SessionController {
    private sessionService: SessionService;
    private formatISO = require('date-fns/formatISO')

    constructor() {
        this.sessionService = new SessionService();
    }

    /**
     * Updates a user's session data
     * @param {profileId} profileId - The id of the user's profile.
     * @param {request} request - The request to update the session.
     * @returns {Promise<boolean | null>} Promise<boolean | null> - Returns `true` if successful, otherwise returns `null`.
     */
    public async UpdateSession(profileId: string, request: SessionUpdateRequest): Promise<boolean | null> {
        try {
            let data = (await this.sessionService.UpdateUserSession(profileId, request)).data;
            let profile = data;
            return profile;
        }
        catch(error: any) {
            let er = error.response.data;
            toast.error(er);
            return null;
        }
    }

    /**
     * Gets a user's session data
     * @param {id} id - The id of the user's profile.
     * @param {session} session - The session number to get.
     * @returns {Promise<ISession | null>} Promise<ISession | null> - Returns the session if successful, otherwise returns `null`.
     */
    public async GetSessionSections(id: string, session: SessionNumber) {
        try{
            let data = (await this.sessionService.GetSessionSection(id, session)).data;
            return data;
        } catch(error:any) {
            let er = error.response.data;
            toast.error(er);
            return null;
        }
    }

    /**
     * Gets a user's family profile
     * @param {id} id - The id of the user's profile.
     * @returns {Promise<any | null>} Promise<any | null> - Returns the family profile if successful, otherwise returns `null`.
     */
    public async GetFamilyProfile(id:string) {
        try {
            let data = (await this.sessionService.GetFamilyProfile(id)).data;
            return data;
        } catch(error:any) {
            let er = error.response.data;
            toast.error(er);
            return null;
        }
    }

    /**
     * Updates a user's family profile score
     * @param {id} id - The id of the user's profile.
     * @param {updatedPoints} updatedPoints - The updated points to add to the user's profile.
     * @returns {Promise<any | null>} Promise<any | null> - Returns the updated family profile if successful, otherwise returns `null`.
     */
    public async UpdateFamilyProfileScore(id: string, updatedPoints: number) {
        try {
            let data = (await this.sessionService.UpdateFamilyProfileScore(id, updatedPoints))!.data;
            return data;
        } catch(error:any) {
            let er = error.response.data;
            toast.error(er);
            return null;
        }
    }

    /**
     * Updates a user's family profile video count
     * @param {id} id - The id of the user's profile.
     * @param {request} request - The request to update the family profile video count.
     * @type {SessionUpdateRequest} SessionUpdateRequest - The request to update the family profile video count.
     * @returns {Promise<any | null>}
     */
    public async UpdateFamilyVideoCount(id: string, request: SessionUpdateRequest) {
        try {
            let data = (await this.sessionService.UpdateFamilyVideoCount(id, request))!.data;
            return data;
        } catch(error:any) {
            let er = error.response.data;
            toast.error(er);
            return null;
        }
    }

    /**
     * Updates the start time of a session.
     * @param {SessionNumber} sessionNumber - The number of the session.
     * @returns {Promise<null>} Promise<null> - Returns `null` if successful, otherwise throws an error.
     */
    public async UpdateSessionStartTime(sessionNumber:SessionNumber) {
        try {
            let data = (await this.sessionService.UpdateSessionStartTime(sessionNumber))!.data;
        } catch (error:any) {
            let er = error.response.data;
            toast.error(er);
            return null;
        }
    }

    /**
     * Updates the end time for a session
     * @param {sessionNumber} sessionNumber - The number of the session.
     * @returns {Promise<null | undefined>} Promise<null | undefined> - Returns `null` if successful, otherwise returns `undefined`.
     */
        public async UpdateSessionEndTime(sessionNumber:SessionNumber) {
            try {
                let data = (await this.sessionService.UpdateSessionEndTime(sessionNumber))!.data;
            } catch (error:any) {
                let er = error.response.data;
                toast.error(er);
                return null;
            }
        }
}


