import {BaseWebService} from "_api/services/interfaces/baseWebService";
import {AuthenticationRequest} from "data/models/auth/authenticationRequest";

export class AuthService extends BaseWebService {
    constructor() {
        super('Auth');
    }

    /**
     * Logs in a user
     * @param {request} request - The request object
     * @returns {Promise<any>} Promise<any> - The response object
     */
    public Login(request: AuthenticationRequest): Promise<any> {
        const endpoint = 'AuthenticateLogin';
        return this.Post(endpoint, request);
    }

    /**
     * Checks the status of the current user
     * @returns {Promise<any>} Promise<any> - The response object
     */
    public async CheckStatus(): Promise<any> {
        const endpoint = 'CheckAppStatus';
        let promise = this.Get(endpoint);
        return promise;
    }

    /**
     * Logs out the current user
     * @returns {Promise<any>} Promise<any> - The response object
     */
    public async Logout(): Promise<any> {
        const endpoint = 'Logout';
        let promise = this.Get(endpoint);
        return promise;
    }

}
