import {Sessions} from "./sessions";

export interface IUserSession {
    parentUserName: string;
    childUserName:string;
    currentSessionData: Sessions
    currentSession : number;
    currentSection: number;
    sessionCompleted: boolean;
}

export class CurrentUserSession implements IUserSession {
    parentUserName: string;
    childUserName:string;
    currentSessionData: Sessions
    currentSession : number;
    currentSection: number;
    sessionCompleted: boolean;

    constructor() {
        this.parentUserName = "";
        this.childUserName = "";
        this.currentSessionData = new Sessions();
        this.currentSession = 0;
        this.currentSection = 0;
        this.sessionCompleted = false;
    }
}
