import { lazy } from 'react';
import {Outlet} from "react-router";
import PageLoader from "../components/shared/PageLoader";
import ExternalLayout from "../components/layout/external/ExternalLayout";

const HomePage = PageLoader(lazy(()=> import("pages/external/authentication")));

const ExternalRoutes = {
    path: '/',
    children: [
        {
            path: '/',
            element: (
                <ExternalLayout>
                    <Outlet />
                </ExternalLayout>
            ),
            children: [
                {
                    path: '/',
                    element: <HomePage />
                },
                {
                    path: 'login',
                    element: <HomePage />
                }
            ]

        }
    ]
};
export default ExternalRoutes;
