export const WelcomeVideoTitle: string = 'Welcome Video';
export const WelcomeVideoUrl: string = 'https://vimeo.com/898952788';
export const Session1VideoTitle: string = 'Session 1 Video';
export const Session1VideoUrl: string = 'https://vimeo.com/898951370';
export const Session2VideoTitle: string = 'Session 2 Video';
export const Session2VideoUrl: string = 'https://vimeo.com/898951765';
export const Session3VideoTitle: string = 'Session 3 Video';
export const Session3VideoUrl: string = 'https://vimeo.com/898952338';
export const WrapUpVideoTitle: string = 'Wrap Up';
export const WrapUpVideoUrl: string = 'https://vimeo.com/898952803';
export const CuttingTechniquesVideoTitle: string = 'Cutting Techniques';
export const CuttingTechniquesVideoUrl: string = 'https://vimeo.com/898950959';
export const MeasuringTechniquesVideoTitle: string = 'Measurement Techniques';
export const MeasuringTechniquesVideoUrl: string = 'https://vimeo.com/898951070';