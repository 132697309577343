import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {UserController} from "../../../_api/controllers/userController";
import {DispatchAction} from "../../../hooks/redux-hooks";
import {logout} from "../../../stores/reducers/authSlice";
import {FamilyProfile} from "../../../data/models/profile/familyProfile";
import Spinner from "components/shared/Spinner";

const LogoutPage = () => {
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const dispatch = DispatchAction();

    /**
     * Handles the logout of the user on mount
     */
    useEffect(() => {
        async function onComponentMount() {
            await logoutUser();
        }

        onComponentMount().then(() => {
            setIsLoading(false);
        });
    }, [])

    /**
     * Logs the user out
     */
    const logoutUser = async () => {
        const uController = new UserController();

        await uController.Logout()

        setTimeout(() => {
            dispatch(logout({
                isLoggedIn: false,
                user: new FamilyProfile()
            }))
            setIsLoading(false);
            navigate("/");
        }, 1000);

    }

    return (
        <>
            <div className="loading-container">
                <Spinner />
            </div>
        </>
    )
}

export default LogoutPage;