import React, {useCallback, useEffect, useState} from "react";
import {Header} from "../Header";
import Sidebar from "../Sidebar";
import {GetReduxState} from "../../../hooks/redux-hooks";
import {RootState} from "../../../stores";
import { SessionType } from "data/models/profile/sessionType";
import {SidebarContext} from "../../../contexts/sidebarContext";

type InternalLayoutProps = {
    children: React.ReactNode
}

const InternalLayout = ({children}: InternalLayoutProps) => {
    const userSession = GetReduxState((state: RootState) => state.Authentication.user)
    const [isOpen, setIsOpen] = useState(false)

    /**
     * Shows the sidebar if the conditions are met
     */
    const showSidebar = useCallback(() => {
        const {loginType} = userSession;

        if(loginType === SessionType.ChildOnly || loginType === SessionType.ParentOnly) {
            return true;
        }

        if (userSession.currentSession >= 0 && userSession.currentProgress === 2) {
            return true
        }
        if (userSession.currentSession >= 1 && userSession.currentProgress === 2) {
            return true
        }
        if (userSession.currentSession >= 2 && userSession.currentProgress === 2) {
            return true
        }
        if (userSession.currentSession >= 3 && userSession.currentProgress === 2) {
            return true
        }
        if (userSession.currentSession >= 3 && userSession.currentProgress === 4) {
            return true
        }
        return false
    }, [userSession]);

    /**
     * Shows the sidebar if the conditions are met on mount
     */
    useEffect(() => {
        showSidebar()

        return () => {
            showSidebar()
        }
    }, [showSidebar, userSession]);

    return (
        <div>
            <Header headerClass={'header-2'}/>
            {showSidebar() && (
                <Sidebar isOpen={isOpen} setIsOpen={setIsOpen}/>
            )}
           {/* @ts-ignore */}
           <SidebarContext.Provider value={{isOpen, setIsOpen}}>
               <div className="content-main">
                   {children}
               </div>
           </SidebarContext.Provider>
        </div>
    )
}

export default InternalLayout