export const toastConfig= {
    style:{
        height:"50px",
        color:'white',
    },
    success:{
        style:{
            background:'#00880F',
            color: 'white'
        }
    },
    error:{
        style:{
            background: '#E60000',
            color: 'white'
        }
    }
};