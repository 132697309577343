import ReactPlayer from "react-player";
import Spinner from "../../shared/Spinner";
import React, {useEffect, useRef, useState} from "react";
import {ValueType} from "../../../data/models/profile/valueType";
import {SectionValue} from "../../../data/models/session/sectionValue";
import {SessionSection} from "../../../data/models/profile/sessionSection";
import {SessionUpdateRequest} from "../../../data/dto/profile/SessionUpdateRequest";
import {SessionController} from "../../../_api/controllers/sessionController";
import useAuth from "../../../hooks/useAuth";
import {useLocation} from "react-router-dom";
import {GetReduxState} from "../../../hooks/redux-hooks";
import {RootState} from "../../../stores";

// Character image imports
const characterOne = '../../../images/character/CH_Carrot01.png'
// const characterTwo = '../../../images/character/CH_Carrot02.png'
// const characterThree = '../../../images/character/CH_Carrot03.png'
// const characterFour = '../../../images/character/CH_Carrot04.png'

const StaticVideoViewer = () => {
    const {user} = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [familyProfile, setFamilyProfile] = useState<any>(null);
    const [videoCount, setVideoCount] = useState<number>(0);
    const [hasWatchedVideo, setHasWatchedVideo] = useState<boolean>(false);
    const isFirstRender = useRef(true)
    const location = useLocation();

    /**
     * Get User session from Redux
     */
    const userSession = GetReduxState((state: RootState) => state.Authentication.user)

    const sessionController: SessionController = new SessionController();

    const {title, videoUrl} = location.state;

    /**
     * Gets the family profile data
     */
    useEffect(() => {
        //Get User Data For Session 1
        setLoading(true);
        const GetFamilyProfile = async (Set: any, setLoad: any) => {
            let data: SessionSection[] = await sessionController.GetFamilyProfile(user.userProfileId)
            Set(data);

            setLoad(false);
        }

        GetFamilyProfile(setFamilyProfile, setLoading)
    }, [user.userProfileId])

    /**
     * Watch for changes to the family profile
     */
    useEffect(() => {

    }, [familyProfile])

    /**
     * Set the loading state to false when the component is done loading
     */
    useEffect(() => {
        if (loading) {
            setLoading(false);
        }

    }, [loading])

    /**
     * Submit the video count when the user has watched the video for the first time
     */
    const handleSubmit = async () => {
        let data: SectionValue[] = [];
        let output: SessionSection;
        let existingPointValue;
        let request: SessionUpdateRequest;

        // Session 1
        if (location.pathname.startsWith('/session-one')) {
            // If the user has already watched the video, increment the video count
            let lastItem = familyProfile?.sectionOne[familyProfile?.sectionOne.length - 1];

            if (lastItem?.sectionName === `Bonus Points - ${title}`) {
                existingPointValue = lastItem.values[0].userValue;

                // @ts-ignore
                existingPointValue = parseInt(existingPointValue) + videoCount;
                data.push({
                    valueName: "watchVideoSecondTime",
                    type: ValueType.freeForm,
                    userValue: existingPointValue.toString()
                })

                output = {
                    sectionName: `Bonus Points - ${title}`,
                    values: data
                }

                request = {
                    Section: output,
                    SessionSection: familyProfile?.sectionOne.length - 1,
                    SessionNumber: 0,
                }

            } else {
                // If the user has not watched the video, set the video count to 1
                // @ts-ignore
                data.push({
                    valueName: "watchVideoSecondTime",
                    type: ValueType.freeForm,
                    userValue: videoCount.toString()
                })
                output = {
                    sectionName: `Bonus Points - ${title}`,
                    values: data
                }

                if (location.pathname.startsWith('/session-one')) {
                    request = {
                        Section: output,
                        SessionSection: familyProfile?.sectionOne.length + 1,
                        SessionNumber: 0,
                    }

                }
            }

        }
        // Session 2
        if (location.pathname.startsWith('/session-two')) {
            // If the user has already watched the video, increment the video count
            let lastItem = familyProfile?.sectionTwo[familyProfile?.sectionTwo.length - 1];

            if (lastItem?.sectionName === `Bonus Points - ${title}`) {
                existingPointValue = lastItem.values[0].userValue;

                // @ts-ignore
                existingPointValue = parseInt(existingPointValue) + videoCount;
                data.push({
                    valueName: "watchVideoSecondTime",
                    type: ValueType.freeForm,
                    userValue: existingPointValue.toString()
                })

                output = {
                    sectionName: `Bonus Points - ${title}`,
                    values: data
                }

                request = {
                    Section: output,
                    SessionSection: familyProfile?.sectionTwo.length - 1,
                    SessionNumber: 1,
                }
            } else {
                // If the user has not watched the video, set the video count to 1
                // @ts-ignore
                data.push({
                    valueName: "watchVideoSecondTime",
                    type: ValueType.freeForm,
                    userValue: videoCount.toString()
                })
                output = {
                    sectionName: `Bonus Points - ${title}`,
                    values: data
                }

                if (location.pathname.startsWith('/session-two')) {
                    request = {
                        Section: output,
                        SessionSection: familyProfile?.sectionTwo.length + 1,
                        SessionNumber: 1,
                    }
                }
            }
        }
        // Session 3
        if (location.pathname.startsWith('/session-three')) {
            // If the user has already watched the video, increment the video count
            let lastItem = familyProfile?.sectionThree[familyProfile?.sectionThree.length - 1];

            if (lastItem?.sectionName === `Bonus Points - ${title}`) {
                existingPointValue = lastItem.values[0].userValue;

                // @ts-ignore
                existingPointValue = parseInt(existingPointValue) + videoCount;
                data.push({
                    valueName: "watchVideoSecondTime",
                    type: ValueType.freeForm,
                    userValue: existingPointValue.toString()
                })

                output = {
                    sectionName: `Bonus Points - ${title}`,
                    values: data
                }

                request = {
                    Section: output,
                    SessionSection: familyProfile?.sectionThree.length - 1,
                    SessionNumber: 2,
                }
            } else {
                // If the user has not watched the video, set the video count to 1
                // @ts-ignore
                data.push({
                    valueName: "watchVideoSecondTime",
                    type: ValueType.freeForm,
                    userValue: videoCount.toString()
                })
                output = {
                    sectionName: `Bonus Points - ${title}`,
                    values: data
                }

                if (location.pathname.startsWith('/session-three')) {
                    request = {
                        Section: output,
                        SessionSection: familyProfile?.sectionThree.length + 1,
                        SessionNumber: 2,
                    }
                }
            }
        }
        // Session 4
        if (location.pathname.startsWith('/session-four')) {
            // If the user has already watched the video, increment the video count
            let lastItem = familyProfile?.sectionFour[familyProfile?.sectionFour.length - 1];

            if (lastItem.sectionName === `Bonus Points - ${title}`) {
                existingPointValue = lastItem.values[0].userValue;

                // @ts-ignore
                existingPointValue = parseInt(existingPointValue) + videoCount;
                data.push({
                    valueName: "watchVideoSecondTime",
                    type: ValueType.freeForm,
                    userValue: existingPointValue.toString()
                })

                output = {
                    sectionName: `Bonus Points - ${title}`,
                    values: data
                }

                request = {
                    Section: output,
                    SessionSection: familyProfile?.sectionFour.length - 1,
                    SessionNumber: 3,
                }
            } else {
                // If the user has not watched the video, set the video count to 1
                // @ts-ignore
                data.push({
                    valueName: "watchVideoSecondTime",
                    type: ValueType.freeForm,
                    userValue: videoCount.toString()
                })
                output = {
                    sectionName: `Bonus Points - ${title}`,
                    values: data
                }

                if (location.pathname.startsWith('/session-four')) {
                    request = {
                        Section: output,
                        SessionSection: familyProfile?.sectionFour.length + 1,
                        SessionNumber: 3,
                    }
                }
            }
        }

        // Null check
        if (request! === undefined || request === null) {
            return;
        }

        // Submit the video count
        // @ts-ignore
        let response = await sessionController.UpdateFamilyVideoCount(user.userProfileId, request);
    }

    /**
     * Increment the video count when the user has watched the video
     */
    useEffect(() => {
        if (hasWatchedVideo) {
            setVideoCount(videoCount => videoCount + 1);
        }

        return () => {
            setHasWatchedVideo(false);
        }

    }, [hasWatchedVideo]);

    /**
     * Submit the video count when the user has watched the video for the first time
     */
    useEffect(() => {
        if (videoCount !== 0) {
            handleSubmit();
        }

    }, [videoCount])

    /**
     * Reload the page when the user navigates to a new session video url
     */
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
        } else {
            window.location.reload();
            setVideoCount(0)
        }

    }, [location]);

    /**
     * Set the hasWatchedVideo state to true when the video has ended
     */
    const handleVideoEnd = () => {
        setHasWatchedVideo(true)
    }

    /**
     * Gets the character image based on the current location
     */
    const getCharacter = () => {

        return characterOne;

        // if (location.pathname.startsWith('/session-two')) {
        //     return characterTwo;
        // }
        // if (location.pathname.startsWith('/session-three')) {
        //     return characterThree;
        // }
        // if (location.pathname.startsWith('/session-four')) {
        //     return characterFour;
        // }
    }

    return (
        <div className={'video-container'}>
            {!loading ? (
                <div className="content-outer">

                    {/*  Title container */}
                    <div className="session-title-container">
                        <div className="content-avatar">
                            <img src={getCharacter()} alt={'Super Chef character'} width={"100%"}
                            />
                        </div>
                        <div className="content-title">
                            <h1>{title}</h1>
                        </div>
                        <div className="clear"></div>
                    </div>

                    {/* Mobile title container */}
                    <div className="session-title-container mobile">
                        <div className="content-avatar mobile small">
                            <img src={getCharacter()} alt={'Super Chef character'} width={"100%"}/>
                        </div>
                        <div className="content-title mobile">
                            <h1>{title}</h1>
                        </div>
                    </div>

                    {/* Content */}
                    <div className="content-inner">
                        <>
                            <ReactPlayer url={videoUrl}
                                         id={'video-player'}
                                         controls={true}
                                         playing={true}
                                         pip={false}
                                         width={'660px'}
                                         height={'560px'}
                                         config={{file: {attributes: {disablePictureInPicture: 'true'}}}}
                                // onEnded={() =>  handleVideoEnd()}
                            />
                        </>
                    </div>
                </div>
            ) : (
                <div className='loading-container'>
                    <Spinner/>
                </div>
            )}
        </div>
    )
}

export default StaticVideoViewer