import React, { useEffect, useState } from 'react';
import Routes from "./routes";
import {AuthProvider} from "./contexts/authContext";
import {Toaster} from "react-hot-toast";
import {toastConfig} from "./data/models/toasts/toastConfig";
import { UserController } from '_api/controllers/userController';
import Logout from 'pages/internal/auth/Logout';
import { FormContext } from 'contexts/formContext';

function App() {
  const userController:UserController = new UserController();

  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * Wrapper for child components to update isSubmitting state
   * @param isSubmitting 
   */
  const updateIsSubmitting = (isSubmitting:boolean) => {
    setIsSubmitting(isSubmitting);
  }

  /**
   * Handles the logout of the user on window unload
   */
  useEffect(() => {

    const handleUnload = async () => {
        const response = await userController.Logout();

        return response;
    }

    window.addEventListener('unload', handleUnload);

    return () => {
        window.removeEventListener('unload', handleUnload);
    }

  }, [Logout]);

  return (
        <AuthProvider>          
            <>
              <FormContext.Provider
                value={{
                  isSubmitting,
                  updateIsSubmitting
                }}
              >
                <Routes/>
                <Toaster position="top-center"
                    reverseOrder={false}
                    toastOptions={toastConfig}
                    containerStyle={{
                      top: '6rem',
                    }}
                />
              </FormContext.Provider>
            </>
        </AuthProvider>
  );
}

export default App;
